import SecurityPage from "../views/Security/SecurityPage";
import securityApi from "../api/securityApi";
import { SECURITY_PAGE_TYPE } from "../constants/securityConstants";

export default function SecurityApiKey() {
  return <SecurityPage
      pageType={SECURITY_PAGE_TYPE.API_KEY}
      loadApi={securityApi.getApiKeys}
      createApi={securityApi.createApiKey}
      updateApi={securityApi.updateApiKey}
      deleteApi={securityApi.deleteApiKey}
      maxItems={10}
  />;
}